import { comparator } from 'ramda'

/**
 * Compare two values strictly. If they have the same type, then compare them directly.
 * Otherwise, all numbers are considered less than than all other types
 */
function strictIsLessThan(a, b) {
  if (typeof a === typeof b) {
    return a < b
  }
  if (typeof a === 'number') {
    return true
  }
  return false
}

function typedCustomerDefinedId(participant) {
  const id = participant.customerDefinedId

  // Use a regex to test if the ID is purely an integer. This is much stricter than `parseInt`
  // By returning the ID is a number, it will be sorted properly when
  // the number of digits don't match (e.g. 3000 < 10000)
  return /^[+-]?\d+$/.test(id)
    ? Number(id)
    : id
}

export const sortableCustomerDefinedId = (p1, p2) => {
  return comparator(strictIsLessThan)(typedCustomerDefinedId(p1), typedCustomerDefinedId(p2))
}
